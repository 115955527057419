import React from "react";

const Resume = () => {
  return (
    <>
      <section id="resume" className="st-dark-bg">
        <div className="st-height-b100 st-height-lg-b80"></div>
        <div className="container">
          <div className="st-section-heading st-style1">
            <h4 className="st-section-heading-title">RESUME</h4>
            <h2 className="st-section-heading-subtitle">RESUME</h2>
          </div>
          <div className="st-height-b25 st-height-lg-b25"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="st-resume-wrap">
                <div className="st-resume-heading">
                  <img
                    src="assets/img/icon/resume-icon1.png"
                    alt="resume-icon"
                  />
                  <h2 className="st-resume-heading-title">Experience</h2>
                </div>

                <div className="st-height-b50 st-height-lg-b30"></div>

                <div className="st-resume-timeline-wrap">
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Front End Developer
                    </h3>
                    <div className="st-resume-timeline-duration">
                      June 2020 - Present
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Funadiq Pakistan
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        Convert Figma designs to pixel-perfect, responsive HTML
                        layouts using Bootstrap, Tailwind, and React JS,
                        ensuring cross-browser functionality, responsive designs
                        in WordPress and Webflow, Git-based CI/CD, maintaining
                        and expanding web applications for performance, and
                        using Browser Stack for testing.
                      </p>
                    </div>
                  </div>
                  <div className="st-height-b50 st-height-lg-b30"></div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Senior Web Developer
                    </h3>
                    <div className="st-resume-timeline-duration">
                      March 2023 - June 2024
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Digi Marketing Inc
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        Convert Figma designs to pixel-perfect, responsive HTML
                        layouts using Material-UI in React JS, developed the
                        official website by transforming Bootstrap designs into
                        a functional site with React and Node JS, managed the
                        admin panel, ensured cross-browser functionality,
                        responsive layouts, utilized Git for CI/CD, and used
                        Browser Stack for testing and debugging.
                      </p>
                    </div>
                  </div>
                  <div className="st-height-b50 st-height-lg-b30"></div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Web & Software Developer
                    </h3>
                    <div className="st-resume-timeline-duration">
                      January 2018 - March 2023
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Berkshire Global Education UK
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        Design, develop, and deploy websites, web apps, and APIs
                        from concept to implementation, creating user-friendly
                        layouts using modern front-end technologies, maintaining
                        and scaling existing applications, collaborating with
                        teams for innovative solutions, developing and managing
                        databases and backend systems, creating tailored
                        software tools, managing UK document & visa processing
                        for compliance, and customizing WordPress themes to meet
                        client needs.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="st-height-b0 st-height-lg-b50"></div>
              <div className="st-resume-wrap">
                <div className="st-resume-heading">
                  <img
                    src="assets/img/icon/resume-icon2.png"
                    alt="resume-icon"
                  />
                  <h2 className="st-resume-heading-title">
                    Education & Certificates
                  </h2>
                </div>
                <div className="st-height-b50 st-height-lg-b30"></div>

                <div className="st-resume-timeline-wrap">
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      Bachelor of Computer Science
                    </h3>
                    <div className="st-resume-timeline-duration">2014 - 2018</div>
                    <h4 className="st-resume-timeline-subtitle">
                      Pir Mehr Ali Shah - Arid Agriculture University Rawalpindi
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        Bachelor's degree in Computer Science with expertise in
                        web development, front-end technologies, and full-stack
                        solutions.
                      </p>
                    </div>
                  </div>
                  <div className="st-height-b50 st-height-lg-b30"></div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">
                      MERN Stack Developer
                    </h3>
                    <div className="st-resume-timeline-duration">
                      February 2024 - April 2024
                    </div>
                    <h4 className="st-resume-timeline-subtitle">
                      Pie Technologies
                    </h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        I have completed a 3-month intensive MERN Stack
                        Developer course, where I gained hands-on experience in
                        building full-stack web applications using MongoDB,
                        Express.js, React.js, and Node.js. The course covered
                        front-end development with React, creating dynamic and
                        responsive user interfaces, as well as back-end
                        development with Node.js and Express, enabling me to
                        build robust APIs and manage databases using MongoDB.
                        This training enhanced my ability to develop and deploy
                        scalable web applications, ensuring seamless integration
                        between front-end and back-end technologies.
                      </p>
                    </div>
                  </div>
                  <div className="st-height-b50 st-height-lg-b30"></div>
                  <div className="st-resume-timeline">
                    <h3 className="st-resume-timeline-title">React JS Developer</h3>
                    <div className="st-resume-timeline-duration">June 2024</div>
                    <h4 className="st-resume-timeline-subtitle">W3 Schools</h4>
                    <div className="st-resume-timeline-text">
                      <p>
                        I've completed a React JS Developer course from
                        W3Schools, where I gained comprehensive knowledge &
                        hands-on experience in building dynamic & interactive
                        user interfaces. The course covered key concepts such as
                        components, state management, hooks, & virtual DOM,
                        enabling me to efficiently develop modern, responsive
                        applications. Through this training, I improved my
                        skills in structuring reusable code & preparing me to
                        build high-performance solutions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="st-height-b100 st-height-lg-b80"></div>
      </section>
    </>
  );
};

export default Resume;
