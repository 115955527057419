import './App.css';
import RouterForm from './routes';

function App() {
  return (
    <>
    <RouterForm />
    </>
  );
}

export default App;
