import React from "react";

const Header = () => {
  return (
    <>
      <div className="st-site-header st-style1 st-sticky-header">
        <div className="st-main-header">
          <div className="container">
            <div className="st-main-header-in">
              <div className="st-main-header-left">
                <a className="st-site-branding" href="index.html">
                  <h2 className="st-text-block-title">Sh. M Usman Ghani</h2>
                </a>
              </div>
              <div className="st-main-header-right">
                <div className="st-nav">
                  <ul className="st-nav-list st-onepage-nav">
                    <li>
                      <a href="#home" className="st-smooth-move">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="#about" className="st-smooth-move">
                        About
                      </a>
                    </li>
                    <li>
                      <a href="#resume" className="st-smooth-move">
                        Resume
                      </a>
                    </li>
                    <li>
                      <a href="#portfolio" className="st-smooth-move">
                        Portfolio
                      </a>
                    </li>
                    <li>
                      <a href="#contact" className="st-smooth-move">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
