import React from "react";

const About = () => {
  return (
    <>
      <section id="about" className="st-about-wrap">
        <div className="st-height-b100 st-height-lg-b80"></div>
        <div className="container">
          <div className="st-section-heading st-style1">
            <h4 className="st-section-heading-title">ABOUT ME</h4>
            <h2 className="st-section-heading-subtitle">ABOUT ME</h2>
          </div>
          <div className="st-height-b25 st-height-lg-b25"></div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft">
              <div className="st-about-img-wrap">
                <div
                  className="st-about-img st-dynamic-bg st-bg"
                  data-src="/assets/img/section/hero-img.jpg"
                >
                    <img src="/assets/img/section/usman.jpg" alt="usman-img" />
                </div>
              </div>
              <div className="st-height-b0 st-height-lg-b30"></div>
            </div>
            <div className="col-lg-6">
              <div className="st-vertical-middle">
                <div className="st-vertical-middle-in">
                  <div className="st-text-block st-style1">
                    <h2 className="st-text-block-title">
                      Hi There! I'm Sh. M Usman Ghani
                    </h2>
                    <h4 className="st-text-block-subtitle">
                      Web & Software Developer
                    </h4>
                    <div className="st-text-block-text">
                      <p>
                        I am a Web and Software Developer with a strong focus on
                        WordPress & Front End solutions. My work aims to attract, inspire,
                        and create engaging digital experiences that motivate
                        users to interact with content. I strive to build
                        websites that not only meet functional requirements but
                        also deliver a favorable impact on user engagement and
                        brand presence.
                      </p>
                    </div>
                    <ul className="st-text-block-details st-mp0">
                      
                      <li>
                        <span>Phone</span> : +92 342 0057457<span></span>
                      </li>
                      <li>
                        <span>Email</span> : <span>usmanghani0057457@gmail.com</span>
                      </li>
                      <li>
                        <span>From</span> :{" "}
                        <span>Islamabad, Pakistan</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
