import React from "react";

const Contact = () => {
  return (
    <>
      <section id="contact" className="st-dark-bg">
        <div className="st-height-b100 st-height-lg-b80"></div>
        <div className="container">
          <div className="st-section-heading st-style1">
            <h4 className="st-section-heading-title">CONTACT ME</h4>
            <h2 className="st-section-heading-subtitle">CONTACT ME</h2>
          </div>
          <div className="st-height-b25 st-height-lg-b25"></div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="st-contact-title">Just say Hello</h3>
              <div id="st-alert"></div>
              <form
                action="#"
                method="POST"
                className="st-contact-form"
                id="contact-form"
              >
                <div className="st-form-field">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="st-form-field">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Your Email"
                    required
                  />
                </div>
                <div className="st-form-field">
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Your Subject"
                    required
                  />
                </div>
                <div className="st-form-field">
                  <textarea
                    cols="30"
                    rows="10"
                    id="msg"
                    name="msg"
                    placeholder="Your Message"
                    required
                  ></textarea>
                </div>
                <button
                  className="st-btn st-style1 st-color1"
                  type="submit"
                  id="submit"
                  name="submit"
                >
                  Send message
                </button>
              </form>
            </div>
            <div className="st-height-b0 st-height-lg-b30"></div>

            <div className="col-lg-6">
              <div className="st-height-b0 st-height-lg-b40"></div>
              <h3 className="st-contact-title">GET IN TOUCH</h3>
              <div className="st-contact-text">
              Let's make your brand brilliant! I’d love to hear from you! Feel free to reach out!
              </div>
              <div className="st-contact-info-wrap">
                <div className="st-single-contact-info">
                  <i className="fas fa-envelope"></i>
                  <div className="st-single-info-details">
                    <h4>Email</h4>
                    <a href="mailto:usmanghani0057457@gmail.com">usmanghani0057457@gmail.com</a>
                    <a href="mailto:usman@potterswheeldh.com">usman@potterswheeldh.com</a>
                  </div>
                </div>
                <div className="st-single-contact-info">
                  <i className="fas fa-phone-alt"></i>
                  <div className="st-single-info-details">
                    <h4>Phone</h4>
                    <a href="tel:+92 342 0057457">+92 342 0057457</a>
                    <a href="tel:+92 336 5377544">+92 336 5377544</a>
                  </div>
                </div>
                <div className="st-single-contact-info">
                  <i className="fas fa-map-marker-alt"></i>
                  <div className="st-single-info-details">
                    <h4>Address</h4>
                    <span>
                      Islamabad, 44000 <br />
                      Paksitan.
                    </span>
                  </div>
                </div>
                <div className="st-social-info">
                  <div className="st-social-text">
                    Visit my social profile and get connected
                  </div>
                  <div className="st-social-link">
                    <a href="https://api.whatsapp.com/send?phone=923420057457&text=Hi%20Usman" className="st-social-btn active">
                      <span className="st-social-icon">
                        <i className="fab fa-whatsapp"></i>
                      </span>
                      <span className="st-icon-name">Whatsapp</span>
                    </a>
                    <a href="https://www.linkedin.com/in/usmansheikh0057457/" className="st-social-btn">
                      <span className="st-social-icon">
                        <i className="fab fa-linkedin"></i>
                      </span>
                      <span className="st-icon-name">LinkedIn</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="st-height-b100 st-height-lg-b80"></div>
      </section>
    </>
  );
};

export default Contact;
