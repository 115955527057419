import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container">
        <div className="st-copyright-wrap text-center">
          <div className="st-copyright-text">
            © 2024 Sh M Usman Ghani. All Right Reserved.
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
