import React from 'react'

const Portfolio = () => {
  return (
    <>
    <section id="portfolio">
    <div className="st-height-b100 st-height-lg-b80"></div>
    <div className="container">
      <div className="st-section-heading st-style1">
        <h4 className="st-section-heading-title">Latest Works</h4>
        <h2 className="st-section-heading-subtitle">Latest Works</h2>
      </div>
      <div className="st-height-b25 st-height-lg-b25"></div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="https://tripplanner.ai/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                  <img src="/assets/img/tripplanner.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>Trip Planner AI</h5>
                  <p>React/Tailwind</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="https://gatewayconsultacy.com/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                  <img src="/assets/img/gateway.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>Gateway Consultancy</h5>
                  <p>Wordpress</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="https://umrahwithpia.com/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                  <img src="/assets/img/umrahwithpia.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>Umrah With PIA</h5>
                  <p>HTML/CSS/JS/Bootstrap</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="http://emergencepro.co.uk/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                <img src="/assets/img/emergancepro.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>Emergance Pro</h5>
                  <p>Wordpress</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="https://triplehelixsolutions.com/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                <img src="/assets/img/triplehelix.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>Triple Helix Solutions</h5>
                  <p>Wordpress</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="st-portfolio-single st-style1 st-lightgallery">
            <div className="st-portfolio-item">
              <a href="https://www.universityaim.com/" className="st-portfolio st-zoom st-lightbox-item">
                <div className="st-portfolio-img st-zoom-in">
                <img src="/assets/img/universityaim.png" alt="portfolio" className="py-5" />
                </div>
                <div className="st-portfolio-item-hover">
                  <i className="fas fa-plus-circle"></i>
                  <h5>University Aim</h5>
                  <p>Wordpress</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="st-height-b100 st-height-lg-b80"></div>
  </section>
    </>
  )
}

export default Portfolio