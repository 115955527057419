import React from "react";

const Skill = () => {
  return (
    <>
      <section className="st-dark-bg">
        <div className="st-height-b100 st-height-lg-b80"></div>
        <div className="container">
          <div className="st-section-heading st-style1">
            <h4 className="st-section-heading-title">Expertise & Skills</h4>
            <h2 className="st-section-heading-subtitle">Expertise & Skills</h2>
          </div>
          <div className="st-height-b25 st-height-lg-b25"></div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="st-skill-wrap">
                <div className="st-skill-heading">
                  <h2 className="st-skill-title text-center mb-5">
                    All the skills that I have in that field of work are
                    mentioned.
                  </h2>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/wordpress.png"
                          className="img-fluid"
                          alt="Wordpress"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/elementor.png"
                          className="img-fluid"
                          alt="Elementor"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/jetengine-logo.png"
                          className="img-fluid"
                          alt="Jet Engine"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/woo.png"
                          className="img-fluid"
                          alt="Woocommerce"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/wp-bakery-logo.png"
                          className="img-fluid"
                          alt="WP Bakery"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/HTML5.png"
                          className="img-fluid"
                          alt="HTML 5"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/css3.png"
                          className="img-fluid"
                          alt="CSS 3"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/js.png"
                          className="img-fluid"
                          alt="JS"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/bootstrap.png"
                          className="img-fluid"
                          alt="Bootstrap"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/tailwind-css.png"
                          className="img-fluid"
                          alt="Tailwind CSS"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/React-1.png"
                          className="img-fluid"
                          alt="React"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div
                        className="bg-white p-3 rounded d-flex justify-content-center align-items-center"
                        
                      >
                        <img
                          src="/assets/img/mongodb.png"
                          className="img-fluid"
                          alt="MongoDB"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/mysql-thumb.png"
                          className="img-fluid"
                          alt="My Sql"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/github.png"
                          className="img-fluid"
                          alt="Github"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/rest-api.png"
                          className="img-fluid"
                          alt="Rest API"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/redux.png"
                          className="img-fluid"
                          alt="Redux"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/ajax.png"
                          className="img-fluid"
                          alt="Ajax"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-2 mb-4">
                      <div className="bg-white p-3 rounded d-flex justify-content-center align-items-center">
                        <img
                          src="/assets/img/jquery.webp"
                          className="img-fluid"
                          alt="jquery"
                          style={{ width: "128px", height: "128px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Skill;
