import React from "react";

const Service = () => {
  return (
    <>
      <section>
        <div className="st-height-b100 st-height-lg-b80"></div>
        <div className="container">
          <div className="st-section-heading st-style1">
            <h4 className="st-section-heading-title">SERVICES</h4>
            <h2 className="st-section-heading-subtitle">SERVICES</h2>
          </div>
          <div className="st-height-b25 st-height-lg-b25"></div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="st-iconbox st-style1">
                <div className="st-iconbox-icon">
                  <svg viewBox="0 0 512 512">
                    <g>
                      <path d="m336 144a8 8 0 0 0 -8 8v104a8 8 0 0 0 16 0v-104a8 8 0 0 0 -8-8z" />
                      <path d="m336 272a8 8 0 0 0 -8 8v8a8 8 0 0 0 16 0v-8a8 8 0 0 0 -8-8z" />
                      <path d="m496 24h-480a8 8 0 0 0 -8 8v352a8 8 0 0 0 8 8h288v64a32 32 0 0 0 64 0v-64h48a8.008 8.008 0 0 0 5.66-2.34l80-80a8.008 8.008 0 0 0 2.34-5.66v-272a8 8 0 0 0 -8-8zm-144 432a16 16 0 0 1 -32 0v-16h32zm0-32h-32v-8h32zm0-24h-32v-264h32zm0-280h-32v-6.11l.94-1.89h30.12l.94 1.89zm-23.06-24 7.06-14.11 7.06 14.11zm95.06 268.69v-52.69h52.69zm64-68.69h-72a8 8 0 0 0 -8 8v72h-40v-264a8.081 8.081 0 0 0 -.84-3.58l-24-48a8.009 8.009 0 0 0 -14.32 0l-24 48a8.081 8.081 0 0 0 -.84 3.58v264h-280v-336h464z" />
                      <path d="m48 72h72a8 8 0 0 0 0-16h-72a8 8 0 0 0 0 16z" />
                      <path d="m80 88a8 8 0 0 0 -8-8h-24a8 8 0 0 0 0 16h24a8 8 0 0 0 8-8z" />
                      <path d="m288 88a104.118 104.118 0 0 0 -104 104 8 8 0 0 0 16 0 88.1 88.1 0 0 1 88-88 8 8 0 0 0 0-16z" />
                      <path d="m240 184a8 8 0 0 0 -8 8 80.091 80.091 0 0 1 -80 80 8 8 0 0 0 0 16 96.108 96.108 0 0 0 96-96 8 8 0 0 0 -8-8z" />
                      <path d="m456 192a8 8 0 0 0 16 0 80.091 80.091 0 0 0 -80-80 8 8 0 0 0 0 16 64.072 64.072 0 0 1 64 64z" />
                      <path d="m168 216a48 48 0 1 0 -48 48 48.053 48.053 0 0 0 48-48zm-48 32a32 32 0 1 1 32-32 32.036 32.036 0 0 1 -32 32z" />
                      <path d="m264 288a24 24 0 1 0 24 24 24.028 24.028 0 0 0 -24-24zm0 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1 -8 8z" />
                      <path d="m432 112a24 24 0 1 0 -24-24 24.028 24.028 0 0 0 24 24zm0-32a8 8 0 1 1 -8 8 8.009 8.009 0 0 1 8-8z" />
                    </g>
                  </svg>
                </div>
                <h2 className="st-iconbox-title">Custom WordPress Development</h2>
                <div className="st-iconbox-text">
                Specializing in building and customizing WordPress websites, including landing pages, using popular builders like Elementor, WPBakery, and Divi. Expert in converting PSD, Figma, or XD designs into fully functional WordPress sites, ensuring seamless user experiences across all devices.
                </div>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="st-iconbox st-style1">
                <div className="st-iconbox-icon">
                  <svg viewBox="0 0 512 512">
                    <g>
                      <g>
                        <path
                          d="M360,104h-80c-4.418,0-8,3.582-8,8v80c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-80C368,107.582,364.418,104,360,104z
                  M288,184v-52.693L340.693,184H288z M352,172.68L299.32,120H352V172.68z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M360,216h-80c-4.418,0-8,3.582-8,8v80c0,4.418,3.582,8,8,8h80c4.418,0,8-3.582,8-8v-80C368,219.582,364.418,216,360,216z
                  M288,296v-52.685L340.685,296H288z M352,284.688L299.312,232H352V284.688z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="272" y="328" width="64" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="352" y="328" width="16" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="272" y="360" width="96" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="272" y="392" width="96" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="136" y="184" width="80" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="136" y="248" width="80" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="136" y="312" width="80" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <path
                          d="M464,56c-4.418,0-8,3.582-8,8v32h-48V16c0-8.837-7.163-16-16-16H120c-8.837,0-16,7.163-16,16v80H56V64
                 c0-4.418-3.582-8-8-8c-0.48,0-48,0.728-48,64v312c0,30.504,30.4,44.632,46.4,47.84c0.526,0.111,1.062,0.165,1.6,0.16h56v16
                 c0,8.837,7.163,16,16,16h272c8.837,0,16-7.163,16-16v-16h56c0.538,0.005,1.074-0.049,1.6-0.16c16-3.208,46.4-17.336,46.4-47.84
                 V120C512,56.728,464.48,56,464,56z M16,120c0-32.64,14.4-43.088,24-46.4v312.232c-8.773,2.722-16.927,7.139-24,13V120z M104,464
                 H48.88C44.104,462.856,16,455.128,16,432c0-24.96,32.248-31.88,33.6-32.16c3.725-0.76,6.401-4.038,6.4-7.84V112h48V464z M120,88
                 h92.12L120,145.543V88z M232,94.447V152h-92.136L232,94.447z M120,168h112v48H120V168z M120,232h112v48H120V232z M120,296h112v48
                 H120V296z M120,360h94.186L120,424.724V360z M232,367.172V432h-94.337L232,367.172z M392,496H120v-48h272V496z M392,432H248V88
                 h144V432z M392,72H120V16h272V72z M463.136,464H408V112h48v280c-0.001,3.802,2.675,7.08,6.4,7.84
                 c0.368,0.072,33.6,7.008,33.6,32.16C496,455.256,468.072,462.84,463.136,464z M496,398.832c-7.073-5.861-15.227-10.278-24-13V73.6
                 c9.496,3.32,24,13.776,24,46.4V398.832z"
                        />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="200" y="40" width="64" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="280" y="40" width="16" height="16" />
                      </g>
                    </g>
                    <g>
                      <g>
                        <rect x="312" y="40" width="16" height="16" />
                      </g>
                    </g>
                  </svg>
                </div>
                <h2 className="st-iconbox-title">E-Commerce <br/> Solutions</h2>
                <div className="st-iconbox-text">
                Offering comprehensive e-commerce development services, including WooCommerce integration for businesses seeking to enhance their online presence. Providing end-to-end solutions, from setting up product catalogs and payment gateways to optimizing user experience for online stores.
                </div>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="st-iconbox st-style1">
                <div className="st-iconbox-icon">
                  <svg viewBox="0 0 64 64">
                    <path d="m62.618 45-5-10h-1.618v-34h-14v34h-19v-17.217l-4.676-10.286c-.413-.909-1.325-1.497-2.324-1.497s-1.911.588-2.324 1.496l-4.676 10.287v17.217h-2.618l-5 10h5.618v18h50v-18zm-18.618-14h6v-2h-6v-2h4v-2h-4v-2h6v-2h-6v-2h4v-2h-4v-2h6v-2h-6v-2h4v-2h-4v-2h6v-2h-6v-2h10v32h-10zm12.382 6 3 6h-21.764l-3-6zm-39.387-2v-15.132l2.002 1.333 2.003-1.334v15.133zm3.743-17.361-1.741 1.159-3.002-2.001-2.998 2-1.735-1.158 1.654-3.639h6.167zm-5.242-9.316c.18-.393.828-.394 1.008.001l1.67 3.676h-4.349zm-4.496 11.547 1.997 1.333 1.998-1.333v15.13h-3.995zm-3.382 17.13h21.764l-3 6h-21.764zm1.382 8h18.618l3.382-6.764v22.764h-22zm46 16h-22v-22.764l3.382 6.764h18.618z" />
                    <path d="m19 57h2v2h-2z" />
                    <path d="m11 57h2v2h-2z" />
                    <path d="m15 57h2v2h-2z" />
                    <path d="m11 53h10v2h-10z" />
                    <path d="m28 9.657c0-1.465 1.192-2.657 2.657-2.657h.686c1.465 0 2.657 1.192 2.657 2.657 0 .709-.276 1.376-.778 1.879l-5.222 5.222v8.242h6v-5.758l3.464-3.464c1.636-1.635 2.536-3.809 2.536-6.121 0-4.773-3.884-8.657-8.657-8.657h-.686c-4.773 0-8.657 3.884-8.657 8.657v2.343h6zm-2 0v.343h-2v-.343c0-3.671 2.986-6.657 6.657-6.657h.686c3.671 0 6.657 2.986 6.657 6.657 0 1.777-.692 3.449-1.95 4.707l-4.05 4.05v4.586h-2v-5.414l4.637-4.636c.879-.881 1.363-2.051 1.363-3.293 0-2.568-2.089-4.657-4.657-4.657h-.686c-2.568 0-4.657 2.089-4.657 4.657z" />
                    <path d="m28 30c0 1.654 1.346 3 3 3s3-1.346 3-3-1.346-3-3-3-3 1.346-3 3zm4 0c0 .552-.448 1-1 1s-1-.448-1-1 .448-1 1-1 1 .448 1 1z" />
                  </svg>
                </div>
                <h2 className="st-iconbox-title">Front-End <br/>Development</h2>
                <div className="st-iconbox-text">
                Delivering cutting-edge front-end development services with expertise in HTML5, CSS3, JavaScript, and modern frameworks like React and Angular. Proficient in utilizing CSS frameworks such as Bootstrap, Material-UI, and Tailwind to create responsive, fast-loading, and visually engaging websites.
                </div>
              </div>
              <div className="st-height-b30 st-height-lg-b30"></div>
            </div>
          </div>
        </div>
        <div className="st-height-b100 st-height-lg-b80"></div>
      </section>
    </>
  );
};

export default Service;
