import React from 'react'
import Banner from './banner'
import About from './about'
import Service from './service'
import Skill from './skill'
import Resume from './resume'
import Portfolio from './portfolio'
import Contact from './contact'

const Home = () => {
  return (
    <>
    <Banner />
    <About />
    <Service />
    <Skill />
    <Resume />
    <Portfolio />
    <Contact />
    </>
  )
}

export default Home