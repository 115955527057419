import React from "react";

const Banner = () => {
  return (
    <>
      <div className="st-height-b80 st-height-lg-b80"></div>
      <section className="st-hero-wrap st-parallax" id="home">
        <div className="st-hero st-style1 st-ripple-version">
          <div className="container">
            <div className="st-hero-text">
              <h3>Hello, I’m Sh. M</h3>
              <h1>
                Usman <br />
                Ghani
              </h1>
              <h2>Web & Software Developer</h2>
              <div className="st-hero-btn">
                <a
                  href="#contact"
                  className="st-btn st-style1 st-color1 st-smooth-move"
                >
                  Discuss Your Project
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="st-hero-img st-to-right">
          <img
            className="wow fadeInRight"
            src="assets/img/section/usman-bg.png"
            alt="Hero"
          />
          <div className="st-social-group wow fadeInLeft">
            <div className="st-social-link">
              <a href="https://api.whatsapp.com/send?phone=923420057457&text=Hi%20Usman" className="st-social-btn active">
                <span className="st-social-icon">
                  <i className="fab fa-whatsapp"></i>
                </span>
                <span className="st-icon-name">Whatsapp</span>
              </a>
              <a href="https://www.linkedin.com/in/usmansheikh0057457/" className="st-social-btn">
                <span className="st-social-icon">
                  <i className="fab fa-linkedin"></i>
                </span>
                <span className="st-icon-name">LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
        <div id="particles-js"></div>
      </section>
    </>
  );
};

export default Banner;
